import * as React from 'react';
import Seo from '../components/seo';

const fAQPage = () => (
  <div className="container mx-auto py-6 px-4">
    <Seo title="FAQ" description="Häufig gestellte Fragen" />
    <div className="prose">
      <h1>FAQ</h1>
      <h2>Was passiert, wenn es regnet?</h2>
      <p>
        Dann entscheide du, ob du deinen Stand aufbauen möchtest oder nicht. Der
        Flohmarkt wird nicht abgesagt.
      </p>
      <h2>Kann man auch ohne sich zu registrieren mitmachen?</h2>
      <p>Ja, der Stand wird dann nicht in der online Karte auftauchen.</p>
      <h2>Was ist wenn ich nach Anmeldeschluss doch noch mitmachen möchte?</h2>
      <p>
        Kein Problem, der Stand ist dann nicht auf der Karte. Aber Du hast ja
        sicherlich Möglichkeiten, auf deinen Stand aufmerksam zu machen
        (Beschilderungen auf Privatgrund, Kreide Hinweise auf der Straße, etc.).
      </p>
      <h2>
        Was ist, wenn ich doch keine Zeit habe am Tag vom Flohmarkt mitzumachen?
      </h2>
      <p>
        Bis zum Anmeldeschluss am 08.05.2022 kannst du dich, über den Link den
        wir die an deine E-Mail Adresse geschickt haben, auch wieder abmelden.
        Sollte Dir kurzfristig etwas dazwischen kommen, dann baust du deinen
        Stand einfach nicht auf.
      </p>
      <h2>Darf ich Essen und Getränke an meinem Stand verkaufen?</h2>
      <p>Ja, als Privatverkauf.</p>
      <h2>Muss ich etwas bei meinem Stand beachten?</h2>
      <p>
        Du darfst deinen Stand nur auf privatem Grund mit Freigabe durch den/
        die Grundbesitzer_in aufbauen. Im besten Fall bist das du selbst,
        ansonsten hol dir bitte das „Okay“ des/ der Vermieter_in. Die
        Preispolitik und die Auspreisung liegt in deiner Hand. Du kannst auch
        Sachen verschenken. Kommerzielle Anbieter_innen dürfen nicht teilnehmen.
      </p>
      <h2>Wozu muss ich meine Adresse angeben?</h2>
      <p>
        Wir werden wieder eine online Karte bei Google Maps erstellen. Die Karte
        dient der Orientierung und soll es möglich machen, deinen Stand zu
        finden. Dein Name taucht dabei nicht auf der Karte auf. Wir werden
        QR-Codes aushängen und bitten dich, diese gern auch selbst am Stand zu
        platzieren, so dass Besucher_innen die Möglichkeit haben, sich die Karte
        zu laden. Auf der Karte sind auch die Stände der Vereine, die Speisen
        und Getränke anbieten, sowie die öffentliche Toilette verzeichnet.
      </p>
      <h2>
        Ich habe Sorge, dass mein Stand nicht gut gefunden wird. Was kann ich
        tun?
      </h2>
      <p>
        Sei kreativ: entwerfe Schilder und Hinweise, die du so anbringst, dass
        du gefunden werden kannst (bitte nur auf privatem Grund).
      </p>
      <h2>Kostet die Teilnahme am Flohmarkt etwas?</h2>
      <p>Nein.</p>

      <h2>Für was wird meine E-Mail Adresse verwendet?</h2>
      <p>
        Sollte es dringende Infos geben, werden wir dich anschreiben. Nach dem
        Flohmarkt werden deine Daten wieder gelöscht. Über Termine für weitere
        Flohmärkte informiere dich gern im Blättle.
      </p>
      <h2>Wie viele nehmen am Flohmarkt teil?</h2>
      <p>
        Das können wir nicht sagen. Im letzten Jahr waren es ca. 100
        Teilnehmer_innen. Wir werden wieder fleißig Werbung machen und freuen
        uns, wenn du die Info über den Dorfflohmarkt ebenfalls weiter gibst.
      </p>
      <h2>Darf ich auch teilnehmen, wenn ich nicht aus Pfaffenweiler bin?</h2>
      <p>
        Sofern du die Möglichkeit hast, auf einem Privatgrundstück von
        Bewohner_innen in Pfaffenweiler deinen Stand aufzubauen: sehr gern.
        Gerne dürfen Stände auch zusammengelegt werden. Es reicht eine Anmeldung
        pro Stand.
      </p>
      <h2>Muss ich ein Hygienekonzept vorlegen?</h2>
      <p>
        Bitte informiere dich selber, wie die aktuellen Regeln für den Markttag
        aussehen und setze entsprechende Maßnahmen um.
      </p>
      <h2>Ist der Flohmarkt-Tag wieder der autofreie Sonntag?</h2>
      <p>
        Nein, wir bitten dennoch, das Auto stehen zu lassen, um ein möglichst
        unfallfreies Flanieren durch die Straßen zu garantieren. Bitte gebt
        diese Info auch weiter.
      </p>
    </div>
  </div>
);

export default fAQPage;
